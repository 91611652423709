html, body {
  height: 100%;
  position: relative;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.ds-layout {
  flex: 1;
  overflow: hidden;
}

.ds-header-absolute {
  position: absolute;
  top: 64px;
  z-index: 1;
  left: calc(50% - (210px) / 2);
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.ds-header-relative {
  display: none;
  margin-top: calc(-76px / 2);
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.ds-brand-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ds-brand-default__component {
    width: 180px;
    margin: 0;
  }
}

.ds-promotion-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //margin-top: 90px;
  img { max-width: 300px; }
  @include media-breakpoint-down(md) {
    //margin-top: 60px;
  }
  @include media-breakpoint-down(sm) {
    //margin-top: 30px;
  }
  &.ds-promotion-default__mb {
    margin-bottom: 30px;
  }
}

.ds-headline-first {
  .ds-headline-first__component {
    margin: 0;
    color: $primary;
    text-transform: uppercase;
    font-weight: 300;
  }
  &.ds-headline-first__align-center {
    text-align: center;
  }
  &.ds-headline-first__mb {
    margin-bottom: 1.5rem;
  }
}

.ds-headline-second {
  .ds-headline-second__component {
    margin: 0;
    color: $gray-600;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
  &.ds-headline-second__align-center {
    text-align: center;
  }
  &.ds-headline-second__mb {
    margin-bottom: 1.5rem;
  }
}

.ds-form {
  .ds-form__element {
    padding-left: 100px;
    padding-right: 100px;
    @include media-breakpoint-down(lg) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include media-breakpoint-down(md) {
      padding-left: 150px;
      padding-right: 150px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
    .ds-form__disclaimer {
      line-height: 1;
      text-align: center;
    }
    .ds-form__buttons {
      text-align: center;
    }
  }
  .ds-form__thanks {
    display: flex;
    justify-content: center;
    span {
      background: $secondary;
      display: block;
      font-size: 24px;
      padding: 1rem 3rem;
    }
  }
}

.ds-teaser-style-column {
  height: 100vh;
  @include media-breakpoint-down(md) {
    height: auto;
  }
  img {
    display: none;
    width: 100%;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center top;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.ds-form-style-column {
  @include media-breakpoint-up(lg) {
    height: 100vh;
    overflow-x: auto;
  }

  .ds-brand-default {
    padding-top: 20px;
    padding-bottom: 20px;
    @include media-breakpoint-down(md) {
      padding-top: 0;
      margin-top: -60px;
      .ds-brand-default__component {
        width: 120px;
      }
    }
  }
}

.ds-footer {
  margin-top: 40px;
  margin-bottom: 20px;
  .ds-footer__rrss {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    a {
      width: 32px;
      height: 32px;
      display: flex;
      margin: 0 0.5rem;
      justify-content: center;
      align-items: center;
      color: $white;
      background: $primary;
      border-radius: 50%;
      @include transition($transition-base)
      &:hover {
        color: $white;
        background: $secondary;
      }
    }
  }
  .ds-footer__copy {
    font-size: 12px;
    line-height: 1.2;
    color: $secondary;
    padding-left: 100px;
    padding-right: 100px;
    @include media-breakpoint-down(lg) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include media-breakpoint-down(md) {
      padding-left: 150px;
      padding-right: 150px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

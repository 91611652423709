
//
// Textual form controls
//

.form-control {
  border-width: 0 0 $input-border-width 0;
  border-color: $primary;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
}

.form-inline {
  @include media-breakpoint-down(xs) {
    display: block;
  }
}

.form-group__inline {
  label {
    font-size: 16px;
    margin-right: 0.5rem;
  }
  .form-control {
    @include media-breakpoint-up(sm) {
      flex: 1;
    }
    height: 24px;
    padding: 0;
  }
}

.form-group__error,
.form-group__warning {
  display: block;
  width: 100%;
  text-align: right;
  font-size: 12px;
}

.form-group__error {
  color: $danger;
}

.form-group__warning {
  color: $warning;
}

//
// Labels
//

label {
  color: $primary;
}

.form-label__uppercase {
  text-transform: uppercase;
}
